<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      盤點管理
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
            { text: '總倉', value: '總倉' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-checkbox
          v-model="showOnlyInStock"
          label="只顯示有庫存商品"
        ></v-checkbox>
      </v-col>
      <!-- v-if="allowExportRoles.includes(this.$store.state.user.角色)" -->
      <v-col
        cols="auto"
        class="d-none d-sm-block pa-0 ml-auto"
      >
        <v-btn
          color="primary"
          class="mr-2"
          @click="exportToExcel(printProduct, branch)"
          :disabled="filterProducts.length === 0"
          >匯出</v-btn
        >
        <v-btn
          color="primary"
          class=""
          @click="exportAll"
          :disabled="filterProducts.length === 0"
        >
          全部匯出
        </v-btn>
      </v-col>
    </v-row>
    <v-alert type="warning">顯示最新盤點結果（{{ lastInventoryDate }}）</v-alert>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :items="filterProducts"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          }"
        >
          <template v-slot:item.inventory.difference="{ item }">
            <span
              :class="{
                'red--text font-weight-bold': item.inventory.difference > 0,
                'green--text font-weight-bold': item.inventory.difference < 0,
              }"
            >
              {{ item.inventory.difference }}
            </span>
          </template>
          <template v-slot:item.check="{ item }">
            <v-select
              v-model="item.inventory.status""
              dense
              hide-details
              outlined
              :items="status"
              @change="updateStatus(item.inventory, $event)"
              :disabled="!item.inventory?.status"
              :style="{'max-width': '120px'}"
            ></v-select>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <DialogStockRegulate
      v-if="dialog.regulate"
      v-model="dialog.regulate"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
    ></DialogStockRegulate>
    <DialogStockInventory
      v-if="dialog.inventory"
      v-model="dialog.inventory"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
      :inventory="monthlyInventory[selectedStock?._id]"
    ></DialogStockInventory> -->
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import _ from "lodash";
// import DialogStockRegulate from "@/components/dashboard/stock/DialogStockRegulate.vue";
// import DialogStockInventory from "@/components/dashboard/stock/DialogStockInventory.vue";
import dayjs from "dayjs";

export default {
  name: "Inventory",
  mixins: [util],
  components: {
    // DialogStockRegulate,
    // DialogStockInventory,
  },
  data: () => ({
    dialog: {
      regulate: false,
      inventory: false,
    },
    // selectedStock: null,
    branch: "",
    branches: [
      { text: "文賢", value: "文賢" },
      { text: "崇善", value: "崇善" },
      { text: "奇美", value: "奇美" },
      { text: "總倉", value: "總倉" },
    ],
    products: [],
    inventory: [],
    inventoryMap: {},
    data: [],
    keyword: "",
    showOnlyInStock: true,
    status: [
      { text: "待審核", value: "progress" },
      { text: "已完成", value: "finished" },
      // { text: "已作廢", value: "cancel" },
    ],
    headers: [
      { text: "日期", value: "inventory.date", align: "center" },
      { text: "商品名稱", value: "name", sortable: false },
      { text: "庫存量", value: "inventory.stock.amount", align: "left", sortable: false },
      {
        text: "盤點量",
        value: "inventory.amount",
        align: "left",
        sortable: false,
      },
      { text: "差額", value: "inventory.difference", align: "left" },
      { text: "即時庫存量", value: "amount", align: "center", sortable: false },
      { text: "更新時間", value: "inventory.createdAt", align: "left" },
      { text: "審核", value: "check", align: "center", sortable: false },
      // { text: "校正", value: "regulate", align: "center", sortable: false },
    ],
    allowExportRoles: ["管理者", "主管"],
  }),
  computed: {
    _: () => _,
    lastInventoryDate() {
      return this.inventory[0]
        ? dayjs(this.inventory[0].createdAt).format("MM/DD")
        : "";
    },
    filterProducts() {
      let products = this.products;
      if (this.keyword) {
        products = _.filter(products, (product) =>
          product.name.toUpperCase().includes(this.keyword.toUpperCase())
        );
      }
      if (this.showOnlyInStock) {
        products = _.filter(products, (product) => product.amount > 0);
      }
      return products;
    },
    monthlyInventory() {
      const groupedItems = _.groupBy(this.inventory, "stock._id");
      const currentMonth = dayjs().month() + 1;

      // 使用 _.mapValues 遍歷 group，保留當月最新的数据，沒有當月數據則為 null
      const filteredItems = _.mapValues(groupedItems, (group) => {
        const currentMonthItems = group.filter(
          (item) => dayjs(item.created).month() + 1 === currentMonth
        );
        if (currentMonthItems.length > 0) {
          return _.maxBy(currentMonthItems, (item) => dayjs(item.created));
        } else {
          return null;
        }
      });
      return _.keyBy(_.values(filteredItems), "stock._id");
    },
    printProduct() {
      let products = this.filterProducts;
      const translatedHeader = {
        date: "日期",
        name: "商品名稱",
        type: '類別',
        category: '分類',
        branch: "分店",
        cost: '成本價',
        price: "售價",
        stock: "庫存量",
        inventory: "盤點量",
        difference: "差額",
        createdAt: "更新時間",
      };
      return products.filter((product) => !!product.inventory?._id).map((product) => {
          return {
            name: product.name,
            type: product.type,
            category: product.category,
            cost: product.cost,
            price: product.price,
            branch: product.branch,
            stock: product.inventory?.stock?.amount,
            inventory: product.inventory?.amount,
            difference: product.inventory?.difference,
            createdAt: product.inventory?.createdAt,
          };
        })
        .map((product) => {
          return Object.fromEntries(
            Object.entries(product).map(([key, value]) => [
              translatedHeader[key] || key,
              value,
            ])
          );
        });
    },
  },
  async created() {
    this.branch = this.$store.state.branch;
  },
  methods: {
    async load() {
      if (!this.branch) return;

      let { data: products } = await this.axios.get(`/product`);
      products = products.filter((o) => o.name);

      const { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      let stocks = _.mapKeys(data, "productId");

      await this.getInventory();
      products = products.map((product) => {
        const stock = stocks[product._id];
        return {
          amount: 0,
          productId: product._id,
          ...product,
          ...stock,
          inventory: this.inventoryMap[stock?._id] ? this.inventoryMap[stock._id] : {},
        };
      });
      this.products = products;
    },
    async getInventory() {
      const { data } = await this.axios.get(`/inventory/${this.branch}`, {
        // params: {
        //   branch: this.branch,
        // },
      });
      this.inventory = data;
      this.inventoryMap = _.mapKeys(data, "stock._id");
    },
    async updateStatus(inventory, status) {
      try {
        this.$vloading.show();
        await this.axios.put(`/inventory/${inventory._id}`, {
          status,
        });
        await this.load();
      } catch (e) {
        this.$toast.error(error.response?.data?.message || "更新失敗");
      }
      this.$vloading.hide();
    },
    async groupByBranch(){
      const branches = this.branches.map((branch) => branch.value);

      //todo: 拿到各 branch 最新盤點資料來計算
      const { data:inventory } = await this.axios.get(`/inventory`);
      const { data: products } = await this.axios.get(`/product`);

      const groupData = _.groupBy(inventory, "stock.productId");
      // const productMap = _.keyBy(products, "_id");

      const inStock = products.filter((product) => !!groupData[product._id] && groupData[product._id].length!==0)
      .map((product) => {
        return {
          ...product,
          inventories: groupData[product._id]
        }
      }).map((product) => {
        const amounts = branches.reduce((preVal, branch) => {
          return {
            ...preVal,
            [branch]: product.inventories.find(({branch: b}) => b === branch)?.amount ?? 0
          }
        }, {});
        return {
          ...product,
          ...amounts,
          totalAmount: _.sum(Object.values(amounts)),
        }
      })
      return inStock
    },
    async exportAll(){
      this.$vloading.show()

      const groupResult = await this.groupByBranch();
      const { sumByCategory, sumByType, lackPrice } = this.calculatedSum(groupResult);
      this.$vloading.hide()

      if (lackPrice.size !== 0) {
        if (
          !confirm(
            `部分商品缺乏售價，計算總額時將視為 0，是否確認印出? \n缺乏售價之商品名稱為：${Array.from(
              lackPrice
            ).join(",")}`
          )
        )
          return;
      }

      const translatedHeader = {
        name: "商品名稱",
        price: "售價",
        type: '類別',
        category: '分類',
        totalAmount: "總量",
      };
      const translatedGroupResult = groupResult
      .map(({name, price, type, category, totalAmount, 文賢, 崇善, 奇美, 總倉}) => {
        return {
          name, price, type, category, totalAmount, 文賢, 崇善, 奇美, 總倉
        }
      })
      .map((product) => {
        return Object.fromEntries(
            Object.entries(product).map(([key, value]) => [
              translatedHeader[key] || key,
              value,
            ])
          );
      });
      this.exportToExcelSheets(
        [
          { data: translatedGroupResult, sheetName: "總計" },
          { data: sumByCategory, sheetName: "類別總計" },
          { data: sumByType, sheetName: "分類總計" },
        ],
        "盤點總額"
      )
    },
    calculatedSum(result) {
      const products = result;

      const types = _.uniq(
        result
          .filter((product) => product.type)
          .map((product) =>  product.type)
      );
      const category = _.uniq(
        result
          .filter((product) => product.category)
          .map((product) => product.category)
      );
      let lackPrice = new Set();

      const sumByType = types.map((type) => {
        const amounts = this.branches
          .map((branch) => branch.value)
          .reduce((pre, branch) => {
            return {
              ...pre,
              [branch]: _.sumBy(products, function (o) {
                if (o.type === type && _.isNumber(o.price)) {
                  return o.price * o[branch];
                } else if (o.type === type) {
                  // lackPrice = [...lackPrice, o.name];
                  lackPrice.add(o.name);
                }
                return 0;
              }),
            };
          }, {});

        return {
          商品類別: type,
          ...amounts,
        };
      });

      const sumByCategory = category.map((category) => {
        const amounts = this.branches
          .map((branch) => branch.value)
          .reduce((pre, branch) => {
            return {
              ...pre,
              [branch]: _.sumBy(products, function (o) {
                if (o.category === category && _.isNumber(o.price)) {
                  return o.price * o[branch];
                } else if (o.category === category) {
                  lackPrice.add(o.name);
                }
                return 0;
              }),
            };
          }, {});

        return {
          分類: category,
          ...amounts,
        };
      });
      return { sumByCategory, sumByType, lackPrice };
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss"></style>
